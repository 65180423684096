import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import PaddedFlexCentered from 'components/PaddedFlexCentered'

interface Props {
  onConfirm: () => void
}
const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    marginTop: theme.spacing(1),
  },
}))
export const ForgotPasswordSubmitted = (props: Props) => {
  const classes = useStyles()
  const {
    onConfirm,
  } = props
  return (
    <>
      <PaddedFlexCentered>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <p>
          A link to reset your password will be emailed to you.
        </p>

        <Button
          className={classes.button}
          color="primary"
          fullWidth
          onClick={onConfirm}
          type="button"
          variant="contained"
        >
          Return to Sign in
        </Button>

      </PaddedFlexCentered>
    </>

  )
}

export default ForgotPasswordSubmitted
