import React, { useEffect, useState } from 'react'
import { Button, Container, CssBaseline, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import Logo from '../../logo/testoil-logo.png'
import { RequestPasswordResetDTO } from 'api'
import useApi from 'hooks/useApi'

import ForgotPasswordSubmitted from './ForgotPasswordSubmitted'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required()
    .email('Pleaser enter a valid email'),
})

export interface ForgotPasswordFormValues {
  email: string
}

export const ForgotPassword = () => {
  const classes = useStyles()
  const [email, setEmail] = useState<RequestPasswordResetDTO>()
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const forgotPasswordFetchState = useApi<never, RequestPasswordResetDTO>(email ? 'v1/users/request-reset-password' : undefined, undefined, {
    requestOptions: {
      method: 'POST',
      body: email ? JSON.stringify(email) : undefined,
    },
    dependencies: [email],
  })

  useEffect(() => {
    if(forgotPasswordFetchState.statusCode === 200) {
      setShowInfo(true)
    }
  }, [forgotPasswordFetchState.statusCode])

  const handleSubmit = (formVals: ForgotPasswordFormValues) => {
    setEmail({ email: formVals.email })
  }
  const handleModalConfirm = () => {
    window.location.href = '/signin'
  }
  if(showInfo) {
    return (<ForgotPasswordSubmitted onConfirm={handleModalConfirm} />)
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <img src={Logo} alt="Eurofins TestOil Logo" style={{width: "400px", paddingTop: "50px", paddingBottom: "50px"}} />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset AssetLink Password
          </Typography>
          <Typography component="h5" variant="subtitle2">
            We will send you a link to reset your password.
          </Typography>
          <Formik
            initialValues={{ email: '', password: '' } as ForgotPasswordFormValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
            render={(formikBag: FormikProps<ForgotPasswordFormValues>) => {
              const {
                isSubmitting,
                isValid,
                errors,
                touched,
              } = formikBag

              return (
                <Form noValidate>
                  <Field
                    name="email"
                    render={({ field }: FieldProps) => (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={!!(errors.email && touched.email)}
                        helperText={touched.email && errors.email}
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              )
            }}
          />
        </div>
      </Container>
    )
  }
}

export default ForgotPassword
