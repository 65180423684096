import React from 'react'

import ForgotPassword from 'components/ForgotPassword/ForgotPassword'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const ForgotPasswordPage = () => {
  return (
    <>
      <SiteHeadMetadata title="Forgot Password" />
      <ForgotPassword />
    </>
  )
}

export default ForgotPasswordPage
